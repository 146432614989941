/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

.nav-bar-link {
    border-radius: 4px;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-left: 24px;
    padding-top: 4px;
    padding-bottom: 4px;
    margin-top: 4px;
    margin-bottom: 4px;
    text-decoration: none;
    color: rgba(0, 0, 0, 0.6);
    
}
.nav-bar-link-active {
    color: black;
    padding-left: 32px;
}
.nav-bar-link:hover {
    color: black;
    background-color: rgba(0, 0, 0, 0.1);
}
