/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

#mfa-wrapper {
    width: 100vw;
    height: 100vh;
    background-color: antiquewhite;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    
}

#mfa-background-image {
    flex: 1;
    width: 100vw;
    background-image: linear-gradient(#ef3c39d7, #ef3c39d7), url('../../assets/fed-ex-banner-2.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position-y: center;
    background-position-x: center;
}

#mfa-background-solid {
    flex: 2;
    width: 100vw;
    background-color: rgb(219, 219, 219);
}

#mfa-card {
    position: absolute;
    margin-right: 12px;
    margin-left: 12px;
    
}
.mfa-input {
    font-size: 12vmin;
    text-align: center;
    
}

#mfa-logo {
    margin: 20px;
    align-self: center;
    max-width: 100%;
}
