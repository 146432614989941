


.day {
    user-select: none;
    width: 30px;
    height: 30px;
    border: 1px solid rgb(240, 240, 240);
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

}

.day:hover {
    border-color: black;
    background-color: rgba(85, 118, 252, 0.25);
}

.day-today {
    border-color: var(--bs-primary);
    font-weight: bold;
}
.day-today:hover {
    border-color: var(--bs-primary) !important;
    font-weight: bold !important;
}

.day-in-range {
    background-color: rgba(85, 118, 252, 0.25);
}

.day-outside {
    opacity: 0.4;
}

.day-disabled {
    cursor: default;
    opacity: 0.5;
    background-color: lightgray;
}

.day-disabled:hover {
    border-color: rgb(240, 240, 240);
    font-weight: normal;
    background-color: lightgray;
}

.day-selected {
    opacity: 1;
    background-color: var(--bs-primary);
    color: white;
}

.day-selected:hover {
    background-color: var(--bs-primary);
}