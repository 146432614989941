/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

#login-wrapper {
    width: 100vw;
    height: 100vh;
    background-color: antiquewhite;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    
}

#login-background-image {
    flex: 1;
    width: 100vw;
    background-image: linear-gradient(#ef3c39d7, #ef3c39d7);
    background-repeat: no-repeat;
    background-size: cover;
    background-position-y: center;
    background-position-x: center;
}

#login-background-solid {
    flex: 2;
    width: 100vw;
    background-color: rgb(219, 219, 219);
}

#login-card {
    position: absolute;
    width: 350px;
    height: 400px;
}
#login-title {
    align-self: center;
    margin-top: 24px;
    margin-bottom: 12px;
    color: var(--bs-primary);
    font-family: 'Gothic A1';
    font-weight: bold;
}